.memberCenter {
  overflow: auto;
  display: flex;
  flex-direction: column;
  .header {
    padding: 24px 20px;
    padding-bottom: 0;
    .info {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 14px;
      .avatar {
        width: 60px;
        height: 60px;
        // background: #f2f2f2;
        // border-radius: 6px;
        
        cursor: pointer;
        &>img{
          width: 60px;
          height: 60px;
          border-radius: 50%;
          overflow: hidden;
        }
      }
      .detail {
        display: flex;
        flex-direction: column;
        gap: 8px;
        h5 {
          margin: 0;
          font-size: 19px;
          font-weight: 500;
          cursor: pointer;
        }
        .extra {
          display: flex;
          gap: 8px;
          align-items: center;
          span {
            font-size: 12px;
            color: #999999;
          }
          button {
            opacity: 0.3;
            display: flex;
            align-items: center;
            gap: 2px;
            border: 1px solid #999999;
            border-radius: 11px;
            // background: transparent; // 22.10.19 SCH
            color: #999999;
            font-size: 11px;
            padding: 3px 5px 3px 10px;
            span {
              flex: 1;
            }
            svg {
              width: 11px;
              height: 11px;
            }
          }
        }
      }
    }
    .additionInfo {
      // opacity: 0.3;
      margin: 0 auto;
      margin-top: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: white;
      border: 1px solid #e7e7ea;
      border-radius: 20px;
      padding: 19px 24px;
      color: #303030;
      font-weight: 500;
      font-size: 17px;
      width: 300px;
      // box-shadow: #00000029 4px 4px inset;
      .comingSoon {
        width: 140px;
        border-radius: 6px;
        border: 1px solid #707070;
        box-shadow: #00000029 3px 4px;
        text-align: center;
        padding: 8px 0;
        span {
          line-height: 22px;
        }
      }
      .usageInfo {
        width: 100%;
        display: flex;
        align-self: center;
        justify-content: center;
        .column {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          flex: 1;
          span {
            font-size: 11px;
            font-weight: bold;
          }
          .num {
            font-size: 24px;
            color: #00f892;
            line-height: 32px;
          }
        }
      }
    }
  }
  .tabContainer {
    margin-top: 26px;
  }
  .container {
    flex: 1;
    overflow-y: auto;
    border-radius: 24px 24px 0 0;
  }
}

.history {
  height: 100%;
  background: #f2f2f2;
  text-align: center;
  padding-top: 13px;
  padding-bottom: 25px;
  overflow-y: auto;
  .tip {
    margin-top: 26px;
    color: #999999;
    font-size: 11px;
  }
  .filter {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    gap: 4px;
    button {
      display: flex;
      align-items: center;
      padding: 8px 16px 7px 16px;
      border-radius: 18px;
      border: 1px solid #cbcbcb;
      height: 35px;
      color: #cbcbcb;
      background: white;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
      &.active {
        border-color: #000000;
        background: #00f892;
        color: black;
      }
      &:disabled {
        border-color: #cbcbcb;
        background: #e7e7ea;
        color: #999;
        cursor: initial;
      }
    }
    .sort {
      button {
        height: 35px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #303030;
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
        gap: 3px;
      }
    }
  }
  .list {
    margin: 11px 5px;

    .listItem {
      border-radius: 2px;
      background: white;

      .status {
        display: flex;
        padding: 10px 18px 12px 19px;
        box-shadow: 0px 2px 0px #303030;
        .startDate,
        .expireDate {
          width: 80px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          span {
            font-size: 11px;
            line-height: 17px;
            &:first-child {
              font-weight: bold;
            }
          }
        }
        .expireDate {
          // 11/3 小白修改
          // align-items: flex-end;
          align-items: flex-start;
        }
        .line {
          position: relative;
          flex: 1;
          svg {
            position: absolute;

            right: 0;
            bottom: -10.0px;
            z-index: 1;
          }
          &:after {
            position: absolute;
            left: 2.5%;
            bottom: 7.5px;
            content: '';
            width: 95%;
            height: 2px;
            background-color: #000000;
            opacity: 0.18;
          }
        }
        &.expired {
          background-color: #b6b6b6;
          svg {
            right: 0;
          }
        }
        &.close {
          background-color: #ffad22;
          svg {
            right: 25%;
          }
        }
        &.using {
          background-color: #00f892;
          svg {
            left: 0;
          }
        }
        &.done {
          background-color: #00f892;
          svg {
            right: 0;
          }
        }
      }
      .detail {
        padding: 15px 16px 17px 16px;
        > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            font-size: 13px;
            line-height: 20px;
          }
        }
        div + div {
          margin-top: 4px;
        }
      }
      & + .listItem {
        margin-top: 16px;
      }
    }
  }
}


