.rent {
  background-color: white; // 20230112 wilson add
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  .menu {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 6px;
    box-shadow: #00000029 6px 8px;
    padding: 28px 19px 16px 30px;
    height: 148px;
    width: 315px;
    cursor: pointer;
    // background: white;
    // box-shadow: #00000026 6px 8px;
    background: #0FF497 ;
    box-shadow: #979797 6px 8px;
    > div {
      span {
        font-weight: 500;
        font-size: 17px;
      }
    }
    &.description {
      > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 6px;
        span:first-child {
          font-size: 21px;
        }
        span:last-child {
          font-weight: normal;
          font-size: 11px;
        }
        &:last-child {
          height: 100%;
          align-items: flex-end;
          justify-content: flex-end;
        }
        &.lock {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          justify-content: center;
          align-items: center;
        }
      }
    }
    // 20230112 wilson add
    &.descriptiongreen {
      > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 6px;
        span:first-child {
          font-size: 21px;
        }
        span:last-child {
          font-weight: normal;
          font-size: 11px;
        }
        &:last-child {
          height: 100%;
          align-items: flex-end;
          justify-content: flex-end;
        }
        &.lock {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          justify-content: center;
          align-items: center;
        }
      }
    }

    &.lookaround {
      padding: 0 12px;
      height: 60px;
      justify-content: center;
      align-items: center;
      svg {
        position: absolute;
        right: 4.5px;
      }
    }

    // 20230112 wilson add
    &.lookaroundgray {
      padding: 0 12px;
      height: 60px;
      justify-content: center;
      align-items: center;
      background: #F6F6F6 ;
      box-shadow: #979797 6px 8px;
      svg {
        position: absolute;
        right: 4.5px;
      }
    }

    &.disabled {
      cursor: initial;
      box-shadow: #00000029 6px 8px inset;
      background: #cbcbcb;
      > div {
        span {
          color: #3030304d;
        }
      }
    }
  }
  .imagesCarousel {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 6px;
    box-shadow: #00000029 6px 8px;
    padding: 28px 19px 16px 30px;
    height: 148px;
    width: 315px;
    cursor: pointer;
    background: white;
    box-shadow: #00000026 6px 8px;
    z-index: 9;
    div {
      position: absolute;
      border-radius: 6px;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
    }
  }
}
.return {
  height: calc(100vh - env(safe-area-inset-bottom));
  display: flex;
  flex-direction: column;
  .qrContainer {
    flex: 1;
  }
  .footer {
    width: 100%;
    padding: 16px 38px;
    padding-top: 45px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 262px;
    gap: 45px;
    button {
      border: none;
      outline: none;
      background: none;
      -webkit-tap-highlight-color: transparent;
      cursor: pointer;
      color: red;
      font-size: 16px;
      text-decoration: none;
    }
  }

  .sponsor {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;

    .mainpic {
      width: 320px;
      max-width: 320px;
    }

    .maincontent {
      width: 100%;
      // text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      .sponsorcon {
        >p {
          line-height: 12px;

          >span {
            font-weight: 700;
          }
        }
      }


      .sponsortag {
        padding: 1px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7px;

        .tagUnder {
          text-decoration: underline;
        }
      }

      .button {
        width: 270px;
        height: 64px;
        background-color: #00f892;
        box-shadow: 6px 8px 0px #00000029;
        border: 1px solid #303030;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #303030;
        font-weight: 500;
        margin-top: 20px;
        font-size: 24px;
        border-radius: 6px;
        cursor: pointer;
      }
    }
  }

  .result {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .button {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 270px;
      height: 64px;
      background-color: #00f892;
      box-shadow: 6px 8px 0px #00000029;
      border: 1px solid #303030;
      border-radius: 6px;
      font-size: 24px;
      color: #303030;
      font-weight: 500;
      margin-top: 20px;
      cursor: pointer;
      svg {
        position: absolute;
        right: 4.5px;
      }
    }
  }
  .selectedStore {
    position: absolute;
    bottom: 42px;
    background: #00000099;
    border-radius: 20px;
    padding: 9px 15px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    font-size: 13px;
    gap: 2px;
  }
}
.cameraContainer {
  position: relative;
  flex: 1;
}
.scannerContainer {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .scannerInner {
    --border-width: 25px;
    --border-thickness: 4px;
    --border-radius: 12px;
    --border-color: white;
    position: relative;
    box-shadow: 0px 0px 0px 2000px rgba(0, 0, 0, 0.3);
    width: 296px;
    height: 296px;
    margin: 0 auto;
    margin-top: 66px;
    border-radius: 12px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: var(--border-color);
      padding: 4px;
      border-radius: var(--border-radius);
      //mask: linear-gradient(transparent 0%, white 100%) top / calc(100% - 25px * 2) 4px,
      //  linear-gradient(transparent 0%, white 100%) bottom / calc(100% - 25px * 2) 4px,
      //  linear-gradient(transparent 0%, white 100%) left / 4px calc(100% - 25px * 2),
      //  linear-gradient(transparent 0%, white 100%) right / 4px calc(100% - 25px * 2),
      //  linear-gradient(transparent 0%, white 100%) content-box,
      //  linear-gradient(transparent 0%, white 100%);
      mask: linear-gradient(#fff 0 0) top / calc(100% - 25px * 2) 4px,
      linear-gradient(#fff 0 0) bottom / calc(100% - 25px * 2) 4px,
      linear-gradient(#fff 0 0) left / 4px calc(100% - 25px * 2),
      linear-gradient(#fff 0 0) right / 4px calc(100% - 25px * 2),
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
      mask-composite: exclude;
      mask-repeat: no-repeat;
    }
  }
}

.stepper {
  position: relative;
  width: 100%;
  .steps {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #f2f2f2;
    padding-bottom: 8px;
    .step {
      color: #00f892;
      font-size: 17px;
      font-weight: bold;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;
      &.active {
        opacity: 1;
        visibility: visible;
      }
    }
    .indicator {
      position: absolute;
      bottom: -2px;
      width: 20px;
      height: 2px;
      background: #00f892;
      transition: all 0.3s ease;
    }
  }

  .content {
    position: relative;
    text-align: center;
    margin-top: 0px; // 20230112 wilson change 原本 20px
    font-size: 17px;
    height: 20px;
    color: #444444;
    .stepDesc {
      position: absolute;
      left: 0;
      width: 100%;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;
      &.active {
        opacity: 1;
        visibility: visible;
      }
      &>ul{
        text-align:justify;
        padding-left: 18px;
        &>li{
          font-size: 14px;
          padding-bottom: 8px;
          line-height: 20px;
        }
      }

      .fami_newtag{
        text-align: start;
        font-weight: 900;
        font-size: 13px;

        .fami_fonttag{
          margin: 10px 0 0 0;
          padding: 0 10px;
          >p{
            margin: 0;
          }
        }
      }
    }
  }
}

.dialogHeader {
  margin-top: 20px;
  margin-bottom: 28px;
  h5 {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 7px;
    font-weight: 600;
  }
  p {
    margin: 0;
    font-weight: normal;
  }
}

.detailDialogHeader {
  margin-bottom: 20px;
  h5 {
    color: #00f892;
    margin-top: 8px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .content {
    border-top: 2px solid #f2f2f2;
    border-bottom: 2px solid #f2f2f2;
    margin-bottom: 10px;
    padding: 29px 10px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    > div {
      display: flex;
      justify-content: space-between;
    }
  }
}
.bonusDialogHeader {
  margin-bottom: 20px;
  h5 {
    color: #00f892;
    margin-top: 8px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .content {
    border-top: 2px solid #f2f2f2;
    border-bottom: 2px solid #f2f2f2;
    margin-bottom: 10px;
    padding: 24px 10px;
    display: flex;
    flex-direction: column;
    gap: 17px;
    // 20230505 white add
    height: 300px;
    overflow-y: auto;

    // 20230505 white add
    .list {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    h1 {
      font-size: 24px;
      margin: 0;
      margin-bottom: 7px;
    }
    h3 {
      font-size: 18px;
      margin: 0;
      margin-bottom: 0px;
    }
    h2 {
      font-size: 24px;
      margin: 0;
      margin-bottom: 0px;
    }
    p {
      font-size: 13px;
      font-weight: bold;
      margin: 0;
    }
    .coupon {
      display: flex;
      gap: 1px;
      position: relative;
      border: 1px solid #707070;
      box-shadow: #00000029 6px 8px;
      border-radius: 6px;
      &:before {
        content: '';
        z-index: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-top-right-radius: 22px;
        border-bottom-right-radius: 22px;
        border: 1px solid #707070;
        border-left: 0;
        background: white;
        left: -1px;
        display: block;
        width: 11px;
        height: 22px;
        z-index: 1;
      }
      > div {
        display: flex;

        border-radius: 6px;
        // border: 1px solid #707070;
        &.infoContainer {
          position: relative;
          // box-shadow: #00000029 6px 8px;
          flex: 1;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 16px 14px;
          background: white;
        }

        // &.btnContainer {
        //   display: flex;
        //   align-items: center;
        //   justify-content: center;
        //   width: 60px;
        //   background: linear-gradient(#000 33%, #fff 0%) left/1px 20px repeat-y; /* left */
        // }
        &.btnContainer {
          position: relative;
          z-index: 5;
          background: #00f892;
          box-shadow: #00000029 6px 8px;
          width: 60px;
          button {
            background: none;
            border: none;
            outline: none;
            cursor: pointer;
            width: 100%;
            height: 100%;
            color: black;
          }
        }

        .title {
          font-size: 13px;
          font-weight: 600;
        }
        .date {
          font-size: 9px;
          color: #999999;
        }
      }
    }
  }
  .collectAllBtn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 270px;
    height: 64px;
    background-color: #00f892;
    box-shadow: 6px 8px 0px #00000029;
    border: 1px solid #303030;
    border-radius: 6px;
    font-size: 24px;
    color: #303030;
    font-weight: 600;
    margin: 0 auto;
    margin-top: 14px;
    margin-bottom: 18px;
    cursor: pointer;
    svg {
      position: absolute;
      right: 4.5px;
    }
  }
}
.okButtonStyles {
  background: none;
  color: black;
}

.okButtonStyles2 {
  background-color: black;
  color:white;
  width: 92%;
  border: unset;
  height: 60px;
  border-radius: 4px;
  margin-top: 2rem;
  >span{
    font-size: 16px;
    font-weight: 700;
  }
}

.returnForOther {
  height: calc(100vh - env(safe-area-inset-bottom));
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  background: #f2f2f2;
  padding: 0 23px;
  h5 {
    margin: 0;
    margin-top: 100px;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;
  }
  h6 {
    margin: 0;
    margin-top: 20px;
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 40px;
  }
  p {
    color: #999999;
    font-size: 12px;
    margin: 0;
    margin-bottom: 30px;
  }
  input {
    border: none;
    outline: none;
    width: 100%;
    font-size: 36px;
    height: 58px;
    text-align: center;
    font-weight: 300;
    &:focus {
      outline: none;
    }
  }
  button {
    width: 100%;
    background: black;
    color: white;
    height: 45px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
    border: none;
    outline: none;
    cursor: pointer;
    margin-top: 36px;
  }
  // 21.12.11 SCH
  .list {
    margin: 11px 5px;

    .listItem {
      background: white;
      padding: 29px 19px;
      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          font-size: 13px;
        }
      }
      div + div {
        margin-top: 4px;
      }
      & + .listItem {
        margin-top: 5px;
      }
    }
  }
}

.manualInput {
  border: 1px solid #00000029;
  outline: none;
  width: 100%;
  font-size: 36px;
  height: 58px;
  text-align: center;
  font-weight: 300;
  &:focus {
    outline: none;
  }
}
.manualContainer {
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  .selectedInfo {
    width: 100%;
  }
}
.actionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 100%;
  height: 57px;
  outline: none;
  border-radius: 4px;
  background-color: var(--theme-color);
  font-weight: bold;
  color: white;
  font-size: 16px;

  // margin-top: 35px;
  transition: opacity 0.3s ease;
  cursor: pointer;
  &.cancel {
    background-color: transparent;
    color: black;
  }
  &:hover {
    opacity: 0.7;
  }
}

.currentLocation {
  p {
    font-size: 18px;
    &.title {
      color: green;
      font-size: 18px;
      margin-bottom: 2px;
    }
    &.shopName {
      margin-top: 2px;
      margin-bottom: 12px;
    }
  }
  .tip {
    margin-top: 12px;
    margin-bottom: 2px;
    p {
      margin: 0px;
    }
  }
  .otherComfirm{
    display: flex;
    align-items: center;
    .com2{
      height: 30px;
      display: flex;
      align-items: center;
    }
  }
}
