.discountInfo {
  overflow: hidden;
  // display: flex;
  // flex-direction: column;
  overflow-y: auto;

  .banner {
    width: 100%;
    height: 250px;
    background-color: tomato;
  }

  .imgWrap{
    width: 100%;
    // height: 250px;
    min-height: 250px;
    display: block;

    .imgInner{
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 250px;

      .img {
        max-width:100%;
        border-radius: 6px;
      }
    }
    
  }
  
  .redeemCode {
    width: 100%;
    min-height: 85px;
    background-color: #00f892;
    padding: 11px 18px 10px 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    .code {
      flex: 1;
      text-align: center;
      h3 {
        margin: 0;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 3px;
        white-space: pre-wrap;
        word-break: break-all;
      }
      span {
        font-size: 13px;
      }
    }
    .copyBtn {
      button {
        background-color: #00f892;
        box-shadow: 6px 8px 0px #00000029;
        border: 1px solid #303030;
        border-radius: 6px;
        width: 64px;
        height: 64px;
        font-size: 18px;
        font-weight: 600;
        padding: 8px;
        color: black;
        cursor: pointer;
      }
    }
  }
  .extra {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 18px;
    overflow: hidden;

    > div {
      border: 1px solid #000;
    }
    .dates {
      width: 100%;
      padding: 13px 19px 13px 20px;
      font-size: 15px;
      border-radius: 30px;
      margin-bottom: 14px;
    }
    .description {
      width: 100%;
      border-radius: 6px;
      padding: 20px 19px 16px 20px;
      font-size: 15px;
      box-shadow: 6px 8px 0px #00000029;
      margin-bottom: 18px;
      text-align: center;
      flex: 1;
      overflow-y: auto;
    }
    .message {
      width: 100%;
      border: none;
      outline: none;
      padding: 20px 19px 16px 20px;
      margin-bottom: 18px;
      text-align: center;
    }
    .btn {
      width: 160px;
      height: 48px;
      background: #00f892;
      box-shadow: 6px 8px 0px #00000029;
      border: 1px solid #303030;
      border-radius: 6px;
      color: #303030;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 18px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        position: absolute;
        right: 4.5px;
      }
    }
  }
}
.okButtonStyles {
  background: none;
  color: #00f892;
}
