.indexWrap {
    width: 100%;
    height: 100vh;
    // border: 1px solid #a0a0a0;
    background: linear-gradient(117deg, #83f4a8 0%, #5fc17f 98.93%);
    background-attachment: fixed;
    position: relative;
    overflow: hidden;
  
    .topWrap {
      height: 45%;
      padding: 20px 40px 0 40px;
  
      .innerWrap {
        display: flex;
        justify-content: center;
        height: 100%;
        flex-direction: column;
        gap: 40px;
        transition: 0.4s all ease-in-out;
  
        .logo {
          display: block;
          background-repeat: no-repeat;
          width: 100%;
          height: 28px;
          background-size: contain;
        }
  
        .content {
          color: #fff;
          display: flex;
          flex-direction: column;
          gap: 10px;
          & > h1 {
            font-weight: 700;
          }
  
          & > P{
            color: #fff;
          }
        }
      }
    }
  
  
    .bottomWrap {
      height: 140px;
      width: 100%;
      position: absolute;
      bottom: 0;
  
      .formWrap {
        // padding: 0px 40px 0 40px;
        // padding: 60px 40px 0 40px;
        padding: 0px 40px 0 40px;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
  
        > form {
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 100%;
          margin: 0 auto;
  
          .checkbutton {
            width: 100%;
            display: flex;
            justify-content: center;
  
            .check {
              width: 180px;
              border: unset;
              height: 48px;
              // background: linear-gradient(117deg, #83f4a8 0%, #5fc17f 98.93%);
              background-color: #fff;
              border-radius: 24px;
              color: #fff;
              font-size: 18px;
              cursor: pointer;
              font-weight: 500;
              transition: 0.4s all ease-in-out;
              box-shadow: 0px 4px 8px 0px rgba(95, 193, 127, 0.25);
            }
            .comfirm{
              color: #5fc17f;
            }
  
          }
        }
      }
    }
  }
  